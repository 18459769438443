import React from 'react';
import './eventCard.scss';

const EventCard = ({ event }) => {
const [year, month, day] = event.EventDate.split('-');
const eventDate = new Date(Date.UTC(year, month - 1, day)); // Month is 0-based
const monthName = eventDate.toLocaleString('default', { month: 'short', timeZone: 'UTC' });
const dayNumber = eventDate.getUTCDate();

// Helper function to format the time range for display
const formatTimeRange = (startTime, endTime) => {
  if (startTime.split(" ")[1] === endTime.split(" ")[1]) {
    // Same period (am/pm), only display it on the second time
    return `${startTime.split(" ")[0]} - ${endTime}`;
  } else {
    // Different periods (e.g., "10 am - 11 pm"), include both
    return `${startTime} - ${endTime}`;
  }
};
const normalizeTime = (time) => {
  return time
    .replace(/(\d)(a\.m\.|p\.m\.|am|pm)/gi, "$1 $2") // Add space between time and am/pm if missing
    .replace(/\b(a\.?m|am)\b/gi, "a.m.") // Normalize "a.m" or "am" to "a.m."
    .replace(/\b(p\.?m|pm)\b/gi, "p.m.") // Normalize "p.m" or "pm" to "p.m."
    .replace(/(\.)(?=\.)/g, "") // Remove extra periods 
    .replace(/:00/g, "") // Remove ":00"
    .trim();
};

const normalizeNoon = (time) => {
  const normalizedTime = normalizeTime(time);
  return normalizedTime === "12 p.m." ? "Noon" : normalizedTime;
};

const formattedTime = formatTimeRange(
  normalizeNoon(normalizeTime(event.EventTime.split(' - ')[0].trim())), 
  normalizeNoon(normalizeTime(event.EventTime.split(' - ')[1].trim()))
);
  return (
    <div className="event-card">
      <div className="event-date">
        <div className="event-date-number">{monthName}</div>
        <div className="event-date-month">{dayNumber}</div>
      </div>
      <div className="event-details">
        <a href={event.EventLink} target="_blank" rel="noopener noreferrer">
          <h2 className='event-title'>{event.EventName}</h2>
        </a>
        <p className="event-description">{event.Description}</p>
        <p className="event-time">{formattedTime} | {event.Host}, {event.EventAddress} | {event.Price}</p>
      </div>
    </div>
  );
};

export default EventCard;
