import ARR from "../constants/austinReuseDirectory.constants";
import {base, eventsBase} from "../../dbApi/base";

export const loadedDropdownData = (data, list) => {
  return {
    type: ARR.LOADED_DROPDOWN_DATA,
    data,
    list,
  };
};

// Action to indicate that events data is loading
export const loadingEventsData = () => {
  return {
    type: ARR.LOADING_EVENTS_DATA,
  };
};

// Action to load the events data into the store
export const loadedEventsData = (data) => {
  return {
    type: ARR.LOADED_EVENTS_DATA,
    data,
  };
};

export const fetchItemsDropdownData = (data) => {
  return {
    type: ARR.FETCH_ITEMS_DROPDOWN_DATA,
    data,
  };
};

export const fetchLocationDropdownData = (data) => {
  return {
    type: ARR.FETCH_LOCATION_DROPDOWN_DATA,
    data,
  };
};

export const loadingDropdownData = () => {
  return {
    type: ARR.LOADING_DROPDOWN_DATA,
  };
};

export const dataLoadFail = (error) => {
  return {
    type: ARR.DATA_LOAD_FAIL,
    error,    
  };
};

export const loadingStoreList = () => {
  return {
    type: ARR.LOADING_STORE_LIST,
  };
};

export const loadedStoreList = (data) => {
  return {
    type: ARR.LOADED_STORE_LIST,
    data,
  };
};

export const setSelectedStoreDetails = (data) => {
  return {
    type: ARR.SET_SELECTED_STORE_DETAILS,
    data,
  };
};

// Fetch events data and dispatch loading and success actions
export const fetchEventsData = () => (dispatch) => {
  dispatch(loadingEventsData());  // Dispatch loading action before fetching data

  eventsBase("Table 1")
    .select({})
    .all((err, records) => {
      console.log("Rec: ", records);
      if (err) {
        dispatch(dataLoadFail(err));  // Dispatch failure action with error details
        console.log("Error fetching events:", err);
        return;
      }
      console.log(records.map((record) => record.fields),"eventRecords", records);
      // Dispatch the loaded events data action once data is fetched
      dispatch(loadedEventsData(records?.map((record) => record?.fields)));
    });
};

// to load Looking to dropdown on page load
export const fetchDropdownData = () => (dispatch) => {
  dispatch(loadingDropdownData());

  // connects to Air table BD and fetches required data on load
  base("MAIN DIRECTORY")
    .select({})
    .all((err, records) => {
      if (err) {
        console.log("error", err);
        return;
      }
      let actions = [];
      let speciality = [];
      let city = [];
      const displayableRecords = records.filter((item) => {
        const { Display = "" } = item.fields;
        if (Display.toLowerCase() === "yes") {
          return true;
        }
        return false;
      });
      displayableRecords.forEach((record) => {
        const { Action, Specialty, City } = record.fields;
        if (Action) {
          actions.push(...Action);
        }
        if (Specialty) {
          speciality.push(...Specialty);
        }
        if (City) {
          city.push(City);
        }
      });
      const lookingToData = [...new Set(actions)];
      (lookingToData || []).sort();

      let dropdownData = {
        lookingTo: ["Select", ...lookingToData],
        typeOfItems: ["Select"],
        location: ["Select"],
      };

      // loads LookingTo dropdown with data and saves store list data
      dispatch(loadedDropdownData(dropdownData, displayableRecords));
    });
};

// loads Items dropdown data when user selects Looking to dropdown
export const fetchItemsDropdownOptions =
  (lookingTo) => (dispatch, getState) => {
    const { storeData } = getState().arrReducer;

    const typeOfItems = [];

    storeData.forEach((record) => {
      const {
        SearchBuy = [],
        SearchSell = [],
        SearchDonate = [],
        SearchRepair = [],
        SearchRecycle = [],
        SearchRent = [],
        //als 7/17/2023
        SearchRefill = [],

      } = record.fields;

      if (lookingTo.toLowerCase() === "buy" && SearchBuy.length > 0) {
        typeOfItems.push(...SearchBuy);
      } else if (lookingTo.toLowerCase() === "sell" && SearchSell.length > 0) {
        typeOfItems.push(...SearchSell);
      } else if (
        lookingTo.toLowerCase() === "donate" &&
        SearchDonate.length > 0
      ) {
        typeOfItems.push(...SearchDonate);
      } else if (
        lookingTo.toLowerCase() === "repair" &&
        SearchRepair.length > 0
      ) {
        typeOfItems.push(...SearchRepair);
      } else if (
        lookingTo.toLowerCase() === "recycle" &&
        SearchRecycle.length > 0
      ) {
        typeOfItems.push(...SearchRecycle);
      } else if (
        lookingTo.toLowerCase() === "rent/share" &&
        SearchRent.length > 0
      ) {
        typeOfItems.push(...SearchRent);
      }else if (
        lookingTo.toLowerCase() === "refill" &&
        SearchRefill.length > 0
      ) {
        typeOfItems.push(...SearchRefill);
      }
    });
    const options = [...new Set(typeOfItems)];
    options.sort();

    const dropdownData = {
      typeOfItems: ["Select", ...options],
      location: ["Select"],
    };
    dispatch(fetchItemsDropdownData(dropdownData));
  };

//loads location dropdown data when user selects Items dropdown
export const fetchLocationDropdownOptions =
  (params) => (dispatch, getState) => {
    const { lookingTo, typeOfItems } = params;
    const { storeData } = getState().arrReducer;
    let locationList = [];

    storeData.forEach((record) => {
      const {
        SearchBuy = [],
        SearchSell = [],
        SearchDonate = [],
        SearchRepair = [],
        SearchRecycle = [],
        SearchRent = [],
        SearchRefill = [],
        City,
      } = record.fields;

      if (lookingTo.toLowerCase() === "buy" && SearchBuy.length > 0) {
        if (SearchBuy.includes(typeOfItems) && City) {
          locationList.push(City);
        }
      } else if (lookingTo.toLowerCase() === "sell" && SearchSell.length > 0) {
        if (SearchSell.includes(typeOfItems) && City) {
          locationList.push(City);
        }
      } else if (
        lookingTo.toLowerCase() === "donate" &&
        SearchDonate.length > 0
      ) {
        if (SearchDonate.includes(typeOfItems) && City) {
          locationList.push(City);
        }
      } else if (
        lookingTo.toLowerCase() === "repair" &&
        SearchRepair.length > 0
      ) {
        if (SearchRepair.includes(typeOfItems) && City) {
          locationList.push(City);
        }
      } else if (
        lookingTo.toLowerCase() === "recycle" &&
        SearchRecycle.length > 0
      ) {
        if (SearchRecycle.includes(typeOfItems) && City) {
          locationList.push(City);
        }
      } else if (
        lookingTo.toLowerCase() === "rent/share" &&
        SearchRent.length > 0
      ) {
        if (SearchRent.includes(typeOfItems) && City) {
          locationList.push(City);
        }
      } else if (
        lookingTo.toLowerCase() === "refill" &&
        SearchRefill.length > 0
      ) {
        if (SearchRefill.includes(typeOfItems) && City) {
          locationList.push(City);
        }
      }
      
    });
    const options = [...new Set(locationList)];
    options.sort();

    const dropdownData = {
      location: ["Select", ...options],
      // location: ['Select', 'Austin', 'Near Address', 'Current Address']
    };

    dispatch(fetchLocationDropdownData(dropdownData));
  };

// loads store list when user clicks on the search button
export const handleSearchAction = (params) => (dispatch, getState) => {
  dispatch(loadingStoreList());

  const { lookingTo, typeOfItems, location } = params;
  const { storeData } = getState().arrReducer;

  const filteredRecords = storeData.filter((items) => {
    const {
      SearchBuy = [],
      SearchSell = [],
      SearchDonate = [],
      SearchRepair = [],
      SearchRecycle = [],
      SearchRent = [],
      SearchRefill = [],
      City,
    } = items.fields;

    const hasCity =
      (City || "").toLowerCase() === (location || "").toLowerCase();
    if (lookingTo.toLowerCase() === "buy" && SearchBuy.length > 0) {
      if (SearchBuy.includes(typeOfItems)) {
        return true;
      }
    } else if (lookingTo.toLowerCase() === "sell" && SearchSell.length > 0) {
      if (SearchSell.includes(typeOfItems)) {
        return true;
      }
    } else if (
      lookingTo.toLowerCase() === "donate" &&
      SearchDonate.length > 0
    ) {
      if (SearchDonate.includes(typeOfItems)) {
        return true;
      }
    } else if (
      lookingTo.toLowerCase() === "repair" &&
      SearchRepair.length > 0
    ) {
      if (SearchRepair.includes(typeOfItems)) {
        return true;
      }
    } else if (
      lookingTo.toLowerCase() === "recycle" &&
      SearchRecycle.length > 0
    ) {
      if (SearchRecycle.includes(typeOfItems)) {
        return true;
      }
    } else if (
      lookingTo.toLowerCase() === "rent/share" &&
      SearchRent.length > 0
    ) {
      if (SearchRent.includes(typeOfItems)) {
        return true;
      }
    } else if (
      lookingTo.toLowerCase() === "refill" &&
      SearchRefill.length > 0
    ) {
      if (SearchRefill.includes(typeOfItems)) {
        return true;
      }
    }
    return false;
  });

  // addes formattedaddress with address, zip and city fields if not online type, otherwise adds only address field
  const searchRecords = filteredRecords.map((record) => {
    const { Address, City, Zip, SearchBuy, SearchDonate, TAGOnlineOnly } =
      record.fields;
    const updatedRecord = { ...record.fields };
    const completeAddr =
      Address && City && Zip ? `${Address}, ${City}, ${Zip}` : "";
    updatedRecord.FormattedAddress =
      (TAGOnlineOnly || "").toLowerCase() === "online only"
        ? Address
        : completeAddr;
    if (lookingTo.toLowerCase() === "donate") {
      updatedRecord.donateBuyList = SearchDonate;
    }
    if (lookingTo.toLowerCase() === "buy") {
      updatedRecord.donateBuyList = SearchBuy;
    }
    return {
      ...record,
      fields: updatedRecord,
    };
  });
  const sortedRecords = searchRecords.sort((a, b) =>
    a.fields.OrgName > b.fields.OrgName ? 1 : -1
  );
  dispatch(loadedStoreList(sortedRecords));
};

// resets filter and map on click reset button when store list is empty

export const resetFiltersAndMap = (flag) => {
  return {
    type: ARR.RESET_FILTER_AND_MAP,
    flag,
  };
};

// to store current position in reducer
export const setCurrentPosition = (currentPos) => {
  return {
    type: ARR.SET_CURRENT_POSITION,
    currentPos,
  };
};

// to toggle the flag to display store list
export const displayResultsAction = (flag) => {
  return {
    type: ARR.DISPLAY_RESULTS_ACTION,
    flag,
  };
};

// to store selected location from the Dropdown in reducer
export const selectedLocationAction = (selectedLcation) => {
  return {
    type: ARR.SELECTED_LOCATION_OPTION,
    option: selectedLcation,
  };
};

// 
export const toggleHighlightStore = (selectedLcation) => {
  return {
    type: 'NONE'
  };
};


