import ARR from "../constants/austinReuseDirectory.constants";

const initialState = {
  dropdownData: {},
  storeData: [],
  searchedStoreData: [],
  selectedStoreDetails: {},
  loading: false,
  resetFilter: false,
  selectedLocation: "",
  eventsData: [], // Store events data
  isLoadingEvents: false, // Flag to track loading state
  error: null,
};

const arrReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ARR.LOADING_DROPDOWN_DATA:
    case ARR.LOADING_STORE_LIST:
      return {
        ...state,
        loading: true,
        searchedStoreData: [],
      };
    case ARR.LOADED_DROPDOWN_DATA:
      return {
        ...state,
        loading: false,
        dropdownData: action.data,
        storeData: action.list,
      };
    case ARR.LOADED_STORE_LIST:
      return {
        ...state,
        searchedStoreData: action.data,
      };
      case ARR.LOADING_EVENTS_DATA:
        return {
          ...state,
          isLoadingEvents: true, // Set loading flag to true when events are being fetched
        };
      case ARR.LOADED_EVENTS_DATA:
        return {
          ...state,
          isLoadingEvents: false, // Set loading flag to false when data is successfully loaded
          eventsData: action.data, // Store the fetched events data
        };  
    case ARR.FETCH_ITEMS_DROPDOWN_DATA:
      return {
        ...state,
        dropdownData: {
          ...state.dropdownData,
          typeOfItems: action.data.typeOfItems,
          location: action.data.location,
        },
      };
    case ARR.FETCH_LOCATION_DROPDOWN_DATA:
      return {
        ...state,
        dropdownData: {
          ...state.dropdownData,
          location: action.data.location,
        },
      };
      case ARR.DATA_LOAD_FAIL:
        return {
          ...state,
          error: action.error,
          isLoadingEvents: false,
        };
    case ARR.RESET_FILTER_AND_MAP:
      return {
        ...state,
        resetFilter: action.flag,
      };
    case ARR.SET_CURRENT_POSITION:
      return {
        ...state,
        currentPos: action.currentPos,
      };
    case ARR.SET_SELECTED_STORE_DETAILS:
      return {
        ...state,
        selectedStoreDetails: action.data,
      };
    case ARR.DISPLAY_RESULTS_ACTION:
      return {
        ...state,
        showResults: action.flag,
      };
    case ARR.SELECTED_LOCATION_OPTION:
      return {
        ...state,
        selectedLocation: action.option,
      };
    default:
      return state;
  }
};

export default arrReducer;
