import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import AustinReuseDirectory from './ARR-app/components/containers/AustinReuseDirectory'
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './ARR-app/store/reducers';
import ReactGA from 'react-ga4';

const store = createStore(rootReducer, applyMiddleware(thunk));
ReactGA.initialize('G-6CYG6YSVHY');
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <AustinReuseDirectory />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
