export default {
  LOADING_DROPDOWN_DATA: "LOADING_DROPDOWN_DATA",
  LOADED_DROPDOWN_DATA: "LOADED_DROPDOWN_DATA",
  LOADING_EVENTS_DATA: "LOADING_EVENTS_DATA",
  LOADED_EVENTS_DATA:"LOADED_EVENTS_DATA",
  DATA_LOAD_FAIL: "DATA_LOAD_FAIL",
  LOADING_STORE_LIST: "LOADING_STORE_LIST",
  LOADED_STORE_LIST: "LOADED_STORE_LIST",
  FETCH_ITEMS_DROPDOWN_DATA: "FETCH_ITEMS_DROPDOWN_DATA",
  FETCH_LOCATION_DROPDOWN_DATA: "FETCH_LOCATION_DROPDOWN_DATA",
  RESET_FILTER_AND_MAP: "RESET_FILTER_AND_MAP",
  SET_CURRENT_POSITION: "SET_CURRENT_POSITION",
  SET_SELECTED_STORE_DETAILS: "SET_SELECTED_STORE_DETAILS",
  DISPLAY_RESULTS_ACTION: "DISPLAY_RESULTS_ACTION",
  SELECTED_LOCATION_OPTION: "SELECTED_LOCATION_OPTION",
};
