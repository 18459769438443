import React, { useEffect } from "react";
import EventCard from './eventCard';
import './eventList.scss';
import { useDispatch, useSelector } from "react-redux";
import { fetchEventsData } from "../../../store/actions/austinReuseDirectory.actions"

// Helper function to compare dates and times
const isEventInTheFuture = (eventDateTime) => {
  const currentDateTime = new Date(); // Current date and time
  return eventDateTime > currentDateTime; // Return true if event is in the future
};

// Helper function to get the full Date object from a formatted date string and time
const getEventDateTime = (dateString, times) => {
  let startTime = times[0] || "";
  let endTime = times[1] || "";
  const normalizeNoon = (time) => {
    return time?.toLowerCase() === "noon" ? "12:00 pm" : time;
  };

  const normalizeTime = (time) => {
    return time
      ?.toLowerCase()
      .replace(/(\d)(am|pm)/g, "$1 $2") // Add space between time and am/pm if missing
      .replace(/\s?a\.m\.?/g, " am") // Normalize am
      .replace(/\s?p\.m\.?/g, " pm") // Normalize pm
      .trim();
  };
  startTime = normalizeNoon(startTime);
  startTime = normalizeTime(startTime);

  endTime = normalizeNoon(endTime);
  endTime = normalizeTime(endTime);
 
  // Append ":00" if minutes are missing in the start time
  if (startTime && !startTime.includes(":")) {
    startTime = `${startTime.split(" ")[0]}:00 ${startTime.split(" ")[1] || ""}`.trim();
  }

  // Append ":00" if minutes are missing in the end time
  if (endTime && !endTime.includes(":")) {
    endTime = `${endTime.split(" ")[0]}:00 ${endTime.split(" ")[1] || ""}`.trim();
  }

  // Construct the full date-time string for the start time
  const eventDateTimeString = `${dateString} ${startTime}`;
  const eventDateTime = new Date(eventDateTimeString);
  
  // Log an error and return null if the date is invalid
  if (isNaN(eventDateTime)) {
    console.error(`Invalid date format for event: ${eventDateTimeString}`);
    return null;
  }

  return eventDateTime;
};

const EventList = () => {
  const dispatch = useDispatch();
  const eventsData = useSelector((state) => state.arrReducer.eventsData); // Access events data
  const isLoadingEvents = useSelector((state) => state.arrReducer.isLoadingEvents); // Access loading state
  const { error } = useSelector((state) => state.arrReducer);

  useEffect(() => {
    dispatch(fetchEventsData()); // Dispatch the action to fetch events data
  }, [dispatch]);

  // Handle loading state
  if (isLoadingEvents) {
    return (<><div className="loading-container"><div className="loading-message">Loading events...</div></div></>); // Show loading indicator while events are being fetched
  }

  // Handle error state
  if (error) {
    console.log("Error fetching data:", error.message);
    return (
      <div className="error-container">
        <div className="error-message">
          <h2>Error fetching events</h2>
          <p>{error.message || "An unknown error occurred."}</p>
          {/* <p>Status Code: {error.statusCode || "N/A"}</p> */}
        </div>
      </div>
    )
  }

  const filteredEvents = eventsData
    .filter(event => event.Display ==='Yes') // Only include events where okayToDisplay is true
    .map(event => {
      const timeParts = event.EventTime.split(' - ');

      // Ensure both start and end times exist
      if (timeParts.length < 2 || !timeParts[0].trim() || !timeParts[1].trim()) {
        return null; // Exclude events with missing start or end times
      }

      const [startTime, endTime] = timeParts.map(time => time.trim());

      const eventDateTime = getEventDateTime(event.EventDate, [startTime, endTime]);
      const endTimeObject = getEventDateTime(event.EventDate, ["", endTime]);

      // Exclude events with invalid date objects
      if (!eventDateTime || !endTimeObject || isNaN(eventDateTime) || isNaN(endTimeObject)) {
        return null;
      }

      if (isEventInTheFuture(eventDateTime)) {
        return { ...event, eventDateTime, endTimeObject };
      }
      return null;
    })
    .filter(event => event !== null) // Remove null entries
    .sort((a, b) => {
      // Sort by start time
      const dateComparison = a.eventDateTime - b.eventDateTime;
      if (dateComparison !== 0) {
        return dateComparison;
      }

      // If start times are the same, sort by end time
      return a.endTimeObject - b.endTimeObject;
    })
    .slice(0, 5); // Show the 5 most upcoming events

  return (
    <div className="event-list-container">
      <div className="event-list">
        <h3 style={{ textAlign: "center" }}>Community Events</h3>
        {filteredEvents.length > 0 ? (
          filteredEvents.map((event, index) => (
            <div key={index}>
              <EventCard event={event} />
            </div>
          ))
        ) : (
          <div className="no-events">
            <h3>No upcoming events.</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventList;
