import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Dropdown from "../../common/Dropdown";
import ReactGA from "react-ga4"; // Import Google Analytics
import {
  fetchDropdownData,
  handleSearchAction,
  fetchItemsDropdownOptions,
  setSelectedStoreDetails,
  displayResultsAction,
  fetchEventsData,
} from "../../../store/actions/austinReuseDirectory.actions";

const SearchComponent = (props) => {
  const { onSearchClick } = props;
  const [lookingToIndex, setLookingToIndex] = useState(0);
  const [itemsIndex, setItemsIndex] = useState(0);
  const [showErrMsg, setShowErrMsg] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDropdownData());
    dispatch(fetchEventsData());
  }, [dispatch]);

  const { dropdownData, resetFilter } = useSelector((state) => state.arrReducer);
  const { lookingTo = [], typeOfItems = [] } = dropdownData || {};

  // Reset dropdowns when filters are reset
  useEffect(() => {
    if (resetFilter) {
      setLookingToIndex(0);
      setItemsIndex(0);
    }
  }, [resetFilter]);

  // Load initial dropdown values from the URL
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const savedLookingTo = decodeURIComponent(params.get("lookingTo") || "");
    const savedTypeOfItems = decodeURIComponent(params.get("typeOfItems") || "");

    if (savedLookingTo && lookingTo.includes(savedLookingTo)) {
      const index = lookingTo.indexOf(savedLookingTo);
      setLookingToIndex(index);
      dispatch(fetchItemsDropdownOptions(savedLookingTo)); // Fetch corresponding Type of Items
    }

    if (savedTypeOfItems && typeOfItems.includes(savedTypeOfItems)) {
      setItemsIndex(typeOfItems.indexOf(savedTypeOfItems));
    }
  }, [lookingTo, typeOfItems, dispatch]);

  // Update URL whenever dropdown values change
  const updateURL = (lookingToVal, typeOfItemsVal) => {
    const params = new URLSearchParams();
    if (lookingToVal && lookingToVal !== "Select") {
      params.set("lookingTo", lookingToVal);
    }
    if (typeOfItemsVal && typeOfItemsVal !== "Select") {
      params.set("typeOfItems", typeOfItemsVal);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState({}, "", newUrl);
  };

  // Handle Search Button Click
  const handleSearchClick = () => {
    const isValidLookingTo =
      lookingTo[lookingToIndex]?.toLowerCase() !== "select" && lookingToIndex > 0;
    const isValidTypeOfItems =
      typeOfItems[itemsIndex]?.toLowerCase() !== "select" && itemsIndex > 0;

    if (!isValidLookingTo) {
      setShowErrMsg("Looking To");
      dispatch(displayResultsAction(false));
    } else if (!isValidTypeOfItems) {
      setShowErrMsg("Type of Items");
      dispatch(displayResultsAction(false));
    } else {
      setShowErrMsg("");
      const requestParams = {
        lookingTo: lookingTo[lookingToIndex],
        typeOfItems: typeOfItems[itemsIndex],
      };
      dispatch(handleSearchAction(requestParams));
      dispatch(setSelectedStoreDetails({}));
      // Google Analytics Event Tracking
      ReactGA.event({
        category: "Search",
        action: "Search button clicked",
        label: `LookingTo: ${lookingTo[lookingToIndex]}, Items: ${typeOfItems[itemsIndex]}`,
        debug_mode: true
      });
      onSearchClick();
    }
  };

  return (
    <div className="search-container container">
      <div className="search-container__title">
        <p className="search-container__title-text">
          Find where to donate, resell, buy responsibly, rent, and repair items,
          while supporting our local reuse economy.
        </p>
      </div>
      <div className="note text-center mb-3">Note: Select from left to right.</div>
      {Object.keys(dropdownData).length > 0 && (
        <div className="d-flex search-container__main">
          <div className="search-container__main-section">
            <p className="search-container__main-title">Looking To</p>
            {(lookingTo || []).length > 0 && (
              <Dropdown
                list={lookingTo}
                onselect={(index) => {
                  setLookingToIndex(index);
                  setShowErrMsg("");
                  setItemsIndex(0);
                  dispatch(fetchItemsDropdownOptions(lookingTo[index])); // Fetch new Type of Items
                  updateURL(lookingTo[index], ""); // Reset Type of Items in URL
                }}
                defaultSelectedIndex={lookingToIndex}
                customClass="look-to-dropdown"
              />
            )}
          </div>
          <div className="search-container__main-section">
            <p className="search-container__main-title">Type of Items</p>
            {(typeOfItems || []).length > 0 && (
              <Dropdown
                list={typeOfItems}
                onselect={(index) => {
                  setItemsIndex(index);
                  setShowErrMsg("");
                  updateURL(lookingTo[lookingToIndex], typeOfItems[index]);
                }}
                defaultSelectedIndex={itemsIndex}
                customClass="items-dropdown"
              />
            )}
          </div>
          <button
            type="button"
            className="search-container__main-btn"
            onClick={handleSearchClick}
          >
            Search
          </button>
        </div>
      )}
      {showErrMsg && (
        <div className="d-flex justify-content-center mt-4 error-msg">
          {`Please select an option from dropdown`} <b>{showErrMsg}</b>
        </div>
      )}
    </div>
  );
};

SearchComponent.defaultProps = {};

SearchComponent.propTypes = {
  onSearchClick: PropTypes.func.isRequired,
};

export default SearchComponent;