import Airtable from 'airtable';


// api = Api(os.environ['AIRTABLE_API_KEY'])

export const base = new Airtable({ apiKey: 'pat5qJVRf3fxjD4Jx.d4c98501c3a050c87e870769dcbcb4a93058921949ef4a4cfe59ee3462fd96e2' }).base('appuxsIjlX2GtHEc6');
export const eventsBase = new Airtable({ apiKey: 'pat5qJVRf3fxjD4Jx.d4c98501c3a050c87e870769dcbcb4a93058921949ef4a4cfe59ee3462fd96e2' }).base('appm5EXI0KolNyzMS');

//Alex's Generated Personal Access Token
//patu6E9aoQxeTI7vv.ba204a990c2b2679dd256f13e3b8a5e8edd194f7f226a28020a8a4338155622e
//export default base;